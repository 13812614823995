<section class="main bg-pistachio pl-3 pr-1 lg:px-6 py-10 mx-auto">
  <div class="items-center lg:flex">
    <div class="w-full lg:w-1/2 lg:flex justify-center items-center p-4 lg:p-10">
      <div class="lg:max-w-lg text-center lg:text-left lg:pr-4 flex flex-col items-center lg:items-start">

        <h1 class="text-2xl font-semibold text-gray-800 lg:text-3xl ">
          Was ist <span class="text-amethyst">Reiki</span>?
        </h1>

        <div class="mt-2">
          <span class="inline-block w-40 h-1 bg-amethyst rounded-full"></span>
          <span class="inline-block w-3 h-1 ml-1 bg-amethyst rounded-full"></span>
          <span class="inline-block w-1 h-1 ml-1 bg-amethyst rounded-full"></span>
        </div>

        <div>
          <p class="mt-3 text-gray-600 font-semibold">
            Reiki ist eine einfache und natürliche Heilmethode. Sie ist sanft und wirksam zugleich, schenkt sowohl dem Gebenden als auch dem Empfangenden Ganzheit und bedient sich der Technik des Handauflegens. Reiki gibt es seit Urzeiten - es ist unabhängig vor einer bestimmten Religion, einer Kultur oder einem Glaubenssystems.
          </p>
          <p class="mt-3 text-gray-600">
            Reiki wirkt sich auf den ganzen Körper. Es bringt Gefühle ins Gleichgewicht, klärt das Denken und verbindet uns mit unsere „wahren“ Selbst, was sich auf unser ganzes Wohlbefinden auswirkt.  Reiki trägt zur körperlichen Gesundheit bei, in dem es das Immunsystems stimuliert. Energieblockaden werden gelöst, der Körper wird von Giftstoffen gereinigt. Reiki kann bei kranken Menschen die Symptome lindern, aber auch zur vollständigen Gesundung beitragen. Reiki macht uns unsere emotionalen Prozesse bewusst.
          </p>
          <p class="mt-3 text-gray-600 font-semibold">
            Wir werden und über unsere unterdrückten Gefühle - Trauer, Angst, Wut - klar. Reiki lässt uns zu einem neuen Bewusstsein  erwachen und führt uns zu unserer Quelle zurück.
          </p>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center lg:justify-start w-full lg:w-1/2">
      <div class="lg:max-w-lg text-center lg:text-left lg:pr-4 lg:pl-10 flex flex-col items-center lg:items-start">
              <div class="grid grid-cols-2 gap-4 p-8 lg:p-2">
                <img class="w-full max-w-64 rounded-lg" src="assets/img/reiki2.jpg" alt="reiki healing 1">
                <img class="max-w-64 mt-4 w-full lg:mt-10 rounded-lg" src="assets/img/reiki3.jpg" alt="reiki healing 3">
              </div>
      </div>
    </div>
  </div>

</section>
