<section class="main px-3 lg:px-6 py-10 mx-auto">
  <div class="items-center flex flex-col-reverse lg:flex-row">
    <div class="w-full flex justify-center items-center lg:w-1/2 mt-12 lg:mt-0">
      <img class="w-[16rem] h-[16rem] object-cover xl:w-[24rem] xl:h-[24rem] rounded-full" src="/assets/img/benefits.jpg" alt="Spirituelle Heilung">
    </div>
    <div class="flex items-center justify-center lg:justify-start w-full lg:w-1/2">
      <div class="lg:max-w-lg text-center lg:text-left lg:pr-4 lg:pl-10 flex flex-col items-center lg:items-start">
        <h1 class="text-2xl font-semibold text-gray-800 lg:text-3xl ">Erlebe die <span class="text-amethyst">Vorteile</span><br> von Reiki</h1>
        <div class="mt-2">
          <span class="inline-block w-40 h-1 bg-amethyst rounded-full"></span>
          <span class="inline-block w-3 h-1 ml-1 bg-amethyst rounded-full"></span>
          <span class="inline-block w-1 h-1 ml-1 bg-amethyst rounded-full"></span>
        </div>
        <div>
          <div class="flex mt-4 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Erhöht die Energie (Energiefluss, Vital-Kraft)</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Persönliches Wachstum und Entwicklung</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Erhöhte geistige Klarheit</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Emotionale Heilung und Stabilität</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Erhöht das Selbstbewusstsein</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Reduziert Stress</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Spirituelles Wachstum und Entwicklung</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Klären und Ausgleichen der Chakren</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Linderung chronischer Schmerzen</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Seele und Körperfrieden</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Stärkt das Immunsystem</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Reduziert Spannungen und Ängste</div>
          </div>

          <div class="flex mt-2 items-center">
            <img src="/assets/img/check.png" class="w-5 h-5 mr-4"/>
            <div class="lg:text-lg text-left font-semibold text-gray-700">Verbessert die Durchblutung</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</section>
