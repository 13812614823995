<div class="main px-6 py-16 mx-auto">
  <div class="items-center lg:flex">
    <div class="w-full lg:w-1/2 lg:flex justify-center">
      <div class="lg:max-w-lg text-center lg:text-left pr-4">
        <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl"><span class="text-amethyst">Reiki</span>, deine spirituelle Heilung</h1>
        <p class="mt-3 text-gray-600">Du möchtest ausbalanciert, in Harmonie und frei von Stress durch den Alltag gehen?</p>
        <p class="mt-3 text-gray-600">Dann bist du hier genau richtig!</p>
        <p class="mt-3 text-gray-600">Gönne dir was gutes und aktiviere deine Selbst-Heilungskräfte. Durch meine Reiki Behandlungen helfe ich dir dabei emotionale und innere Blockaden zu lösen.</p>

        <button (click)="goToMessage()" class="px-5 py-2 mt-6 text-sm tracking-wider text-white uppercase transition-colors duration-300 transform bg-amethyst rounded-lg lg:w-auto focus:outline-none">Gratis Erstgespräch</button>
      </div>
    </div>
    <div class="flex items-center justify-center w-full mt-6 lg:mt-0 lg:w-1/2">
      <img class="w-full h-full lg:max-w-xl rounded-2xl xl:mr-12" src="/assets/img/hero.jpg" alt="Spirituelle Heilung">
    </div>
  </div>
</div>
