<section class="bg-white my-16">
  <div class="container px-6 py-10 mx-auto">
    <h1 class="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl ">
      Was meine <span class="text-amethyst">Klienten</span> sagen
    </h1>

    <p class="max-w-2xl mx-auto mt-6 text-center text-gray-500 ">
      „Die Flammen der Heilung und des Segens leuchten bei Tag und bei Nacht“
    </p>

    <div class="grid grid-cols-1 gap-8 mx-auto mt-8 lg:grid-cols-2 xl:mt-10 max-w-7xl">
      <div class="p-6 bg-gray-100 rounded-lg  md:p-8 italic">
        <p class="leading-loose text-gray-500 font-light">
          Während der Fernbehandlung fühlte ich Leichtigkeit und Frieden auch nach der Behandlung spürte ich Erleichterung und die Schweissausbrüche kamen später weniger und weniger.
          Herzlichen Dank!
        </p>

        <div class="flex items-center mt-5">
          <div>
            <h1 class="font-semibold text-amethyst">Gabriela</h1>
            <span class="text-sm text-gray-500 ">aus Zurich</span>
          </div>
        </div>
      </div>

      <div class="p-6 bg-gray-100 rounded-lg  md:p-8 italic">
        <p class="leading-loose text-gray-500 font-light">
          Meine Reiki-Behandlung bei Emely war sehr angenehm. Ich fühlte mich danach sehr leicht und entspannt. Emely schafft eine angenehme Atmosphäre durch die beruhigende Musik und ihrer Ausstrahlung. Ich war sehr zufrieden mit ihrer Behandlung und kann sie mit gutem Gewissen jedem weiterempfehlen.
        </p>

        <div class="flex items-center mt-5">
          <div>
            <h1 class="font-semibold text-amethyst">Eléna</h1>
            <span class="text-sm text-gray-500 ">aus Wallisellen</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
