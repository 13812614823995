import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-what-is-reiki',
  imports: [],
  templateUrl: './what-is-reiki.component.html',
  styleUrl: './what-is-reiki.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhatIsReikiComponent {}
