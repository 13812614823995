<div class="flex flex-wrap items-center justify-evenly w-full bg-pistachio text-sm font-special">

  <div class="h-32 w-80 p-4 m-6 rounded-3xl bg-gradient-to-br from-[rgba(75,30,133,0.7)] to-[rgba(75,30,133,0.3)] text-white flex justify-center items-center">
    <div>
      <p>
        Reiki beruhigt und entspannt das Nervensystem und gleicht so das Auf und Ab der Gefühle aus.
      </p>
    </div>
  </div>

  <div class="h-32 w-80 p-4 m-6 rounded-3xl bg-gradient-to-br from-[rgba(75,30,133,0.7)] to-[rgba(75,30,133,0.4)] text-white flex justify-center items-center">
    <div>
      <p>
        Reiki ist die Energie der bedingungslosen Liebe, die alles verwandelt, was sie berührt- die bedingungslose Liebe Gottes oder des Universums.
      </p>
    </div>
  </div>

  <div class="h-32 w-80 p-4 m-6 rounded-3xl bg-gradient-to-br from-[rgba(75,30,133,0.7)] to-[rgba(75,30,133,0.4)] text-white flex justify-center items-center ">
    <div>
      <p>
        Reiki fliesst uns direkt aus der Quelle allen Lebens, aller Lebendigkeit zu.
      </p>
    </div>
  </div>

</div>

