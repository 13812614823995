import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LifeRuleComponent } from './life-rule/life-rule.component';

@Component({
  selector: 'app-life-rules',
  imports: [LifeRuleComponent],
  templateUrl: './life-rules.component.html',
  styleUrl: './life-rules.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeRulesComponent {
  lifeRules = [
    {
      quote: 'Verdiene Dein Brot ehrlich.',
    },
    {
      quote: 'Gerade heute sorge dich nicht.',
    },
    {
      quote: 'Gerade heute sei nicht ärgerlich.',
    },
    {
      quote: 'Sei dankbar gegenüber allem, was lebt.',
    },
    {
      quote: 'Ehre Deine Lehrer, Eltern und die Älteren.',
    },
  ];
}
