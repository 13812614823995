import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReikiCardsComponent } from './reiki-cards/reiki-cards.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { HeroComponent } from './hero/hero.component';
import { BenefitsComponent } from './benefits/benefits.component';
import { WhatIsReikiComponent } from './what-is-reiki/what-is-reiki.component';
import { LifeRulesComponent } from './life-rules/life-rules.component';

@Component({
  selector: 'app-home',
  imports: [
    ReikiCardsComponent,
    TestimonialsComponent,
    HeroComponent,
    BenefitsComponent,
    WhatIsReikiComponent,
    LifeRulesComponent,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {}
