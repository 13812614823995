<div class="flex items-center justify-center w-full lg:w-1/2">
  <div class="pt-10 pb-4 flex flex-col items-start">
    <h1 class="text-2xl font-semibold text-gray-800 lg:text-3xl ">
      Reiki <span class="text-amethyst">Lebensregeln</span></h1>
    <div class="mt-2">
      <span class="inline-block w-40 h-1 bg-amethyst rounded-full"></span>
      <span class="inline-block w-3 h-1 ml-1 bg-amethyst rounded-full"></span>
      <span class="inline-block w-1 h-1 ml-1 bg-amethyst rounded-full"></span>
    </div>
  </div>
</div>

<div class="w-full p-4 flex flex-wrap justify-evenly">
  @for (lifeRule of lifeRules; track lifeRule.quote) {
    <app-life-rule [lifeRule]="lifeRule"></app-life-rule>
  }
</div>
