import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { LifeRule } from '../life-rule';

@Component({
  selector: 'app-life-rule',
  imports: [],
  templateUrl: './life-rule.component.html',
  styleUrl: './life-rule.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LifeRuleComponent {
  lifeRule = input<LifeRule>();
}
